
<template>
  <div class="login-container">
    <img
      src="@/assets/images/logo-top.png"
      alt=""
      class="login-top"
      @click="$router.push({ path: '/home' })"
    />
    <div class="login-form">
      <div class="lg-tab">
        <div
          class="item"
          :class="{ active: index == checkindex }"
          v-for="(item, index) in tabList"
          :key="index"
          @click="onTab(index)"
        >
          {{ item }}
        </div>
      </div>
      <el-form ref="form" :rules="rules" :model="form">
        <div v-if="checkindex == 1">
          <el-form-item class="login-item" prop="mobile">
            <el-input
              v-model="form.mobile"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>

          <el-form-item class="login-item" prop="password">
            <el-input
              v-model="form.password"
              type="password"
              placeholder="输入密码"
            ></el-input>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item class="login-item" prop="mobile">
            <el-input
              v-model="form.mobile"
              placeholder="请输入手机号"
              @input="validatePhone"
            ></el-input>						
            <div class="txt">{{ errors }}</div>
          </el-form-item>
          <div class="login-item">
            <slider-check @slideFunc="getMsgFormSon"></slider-check>
          </div>
          <el-form-item class="login-item" prop="verCode">
            <el-input
              v-model="form.verCode"
              placeholder="输入验证码"
              class="vercode"
            ></el-input>
            <el-button type="primary" class="vercode-btn" :disabled="!disabled" v-show="show" v-preventReClick @click="getVerifyCode">发送验证码</el-button>
				    <div v-show="!show" class="count">{{count}}s后可重试</div>
          </el-form-item>
        </div>
      </el-form>
      <el-button type="primary" class="login-btn" @click="onLogin" >登录</el-button>
      <div class="opear">
				<!-- <div class="opear-item changePwd" @click="onChangePassword">忘记密码</div> -->
				<div class="opear-item">还没有账号？ <span class="opear-btn" @click="onRegister">立即注册</span></div>
			</div>
    </div>
  </div>
</template>

<script>
import { phoneRules } from "@/utils/rule.js";
import { setToken } from "@/utils/auth";
import SliderCheck from "@/views/pc/login/components/SliderCheck";
export default {
  components: {
    "slider-check": SliderCheck,
  },
  data() {
    return {
      checked: false,
      disabled: false,
      show: true,
      count: '',
      timer: null,
      checkindex: 0,
      tabList: ["验证码登录","密码登录"],
      msgFormSon: "",
      form: {
        mobile: "",
        verCode: "",
        password: '',
      },
      originForm: {},
      errors: "",
      rules: {
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          { validator: phoneRules, trigger: ["blur"] },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        verCode: [
          {
            required: true,
            message: "输入验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  // watch: {
  //   form: {
  //     handler(newVal, oldVal) {
  //       let flag = JSON.stringify(this.form) == JSON.stringify(this.originForm);
  //       if (!flag) {
  //         this.onCheckForm();
  //       }
  //     },
  //     immediate: false,
  //     deep: true, //监听对象
  //   },
  // },
  computed: {
    redirect() {
      return this.$route.query.redirect;
    },

  },
  mounted() {
    // this.onCheckForm();
  },
  methods: {
    //菜单切换
    onTab(index) {
      this.checkindex = index;
      // this.$nextTick(() => {
      //   this.onCheckForm();
      // });
    },
    // onCheckForm() {
    //   this.$refs.form.validate((valid) => {
    //      console.log(valid)
    //     if (valid) {
    //       this.checked = true;
    //     } else {
    //       this.checked = false;
    //     }
    //   });
    // },
    //发送验证码
    getCode(){
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--;
          this.disabled = false;
          } else {
          this.show = true;
          clearInterval(this.timer);
          this.disabled = true;
          this.timer = null;
          }
        }, 1000)
        }
    },
    //滑动验证
    getMsgFormSon(data) {
      this.msgFormSon = data;
      if (this.rightPhone()) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
		rightPhone(){
      //利用正则对手机号匹配
      return /^1[3456789]\d{9}$/.test(this.form.mobile);
    },
		validatePhone(){
			if (this.rightPhone() && this.msgFormSon == true) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
		},
    // 请求短信接口
    getVerifyCode() {
      if (!this.errors) {
        this.$http
				.post("/api/web/sendRegisterSms", this.form)
				.then((res) => {  
          this.$toast(res.msg)       
          this.getCode();
        }).catch(err=>{
          this.$toast(err.msg)
        });
      }
    },   
    onLogin() {
      this.$refs.form.validate((valid) => {       
        if (valid) {
          // const postUrl=this.checkindex?'/api/web/doLoginPwd':'/api/web/doLogin';
          let postUrl,postData;
          const { mobile , verCode , password}=this.form;
          if(!!this.checkindex){
            postUrl='/api/web/doLoginPwd';
            postData={ mobile,password }
          }else{
            postUrl='/api/web/doLogin';
            postData={ mobile,verCode }
          }
          this.$http
            .post(postUrl, postData)
            .then((res) => {
              this.$toast(res.msg || "登录成功");
              setToken(res.data.token);
              setTimeout(() => {
                this.$store.commit("SET_USER_INFO", res.data);
                this.$router.replace(this.redirect || "/");
              }, 100);
            })
            .catch((err) => {
              this.$toast(err.msg || "登录失败");
            });
        } else {
          return false;
        }
      });
    },
    onChangePassword() {
      this.$router.push({ name: "ForgetPwd" });
    },
    onRegister() {
      this.$router.push({ name: "Register" });
    },
  },
};
</script>

<style lang='less' scoped>
.login-container {
  width: 100%;
  height: 100vh;
  padding-top: 70px;
  background-image: url("http://ipqncdn1.artuns.com/Fr2VY-15B2Z2Xbw1Plud86kcglTj");
  background-size: cover;
  .login-top {
    width: 362px;
    cursor: pointer;
  }
  .login-form {
    width: 400px;
    margin: 40px auto 0;
    padding: 10px 30px 30px;
    border-radius: 6px;
    background: #fff;
    .lg-tab {
      display: flex;
      border-bottom: 1px solid #f5f5f5;
      margin-bottom: 30px;
      .item {
        margin-right: 30px;
        line-height: 55px;
        font-size: 16px;
        cursor: pointer;
        color: #999;
        &.active {
          color: #333;
          border-bottom: 2px solid #333;
        }
      }
    }
    .login-item {
      margin-bottom: 30px;
      /deep/.el-input__inner {
        background: #f4f4f4;
        border: 1px solid #dadada;
        border-radius: 4px;
        font-size: 16px;
      }
      .txt {
        color: #f56c6c;
        font-size: 12px;
        line-height: 1;
        padding-top: 4 px;
        position: absolute;
        top: 100%;
        left: 0;
				opacity: 0;
      }
      .vercode {
        width: calc(100% - 140px);
      }
      .vercode-btn {
        width: 120px;
        margin-left: 20px;
        background: #d71a18;
        border: 1px solid #d71a18;
        color: #fff;
        border-radius: 4px;
        &.is-disabled {
          color: #878787;
          background: #dadada;
          border: 1px solid #dadada;
        }
      }
      .count{
        float: right;
        width: 120px;
        height: 40px;
        margin-left: 20px;
        color: #5f5f5f;
        background: #dddddd;
        border-radius: 4px;
      }
    }
    .login-btn {
      width: 340px;
      height: 44px;
      margin-top: 10px;
      margin-bottom: 15px;
      background: #d71a18;
      border-radius: 4px;
      &.is-disabled {
        color: #878787;
        background: #dadada;
        border: 1px solid #dadada;
      }
    }
    .opear {
      display: flex;
      justify-content: space-between;
      color: #999;
      font-size: 12px;
      .opear-btn {
        color: #d71a18;
        cursor: pointer;
      }
    }
    .changePwd {
      cursor: pointer;
    }
  }
}
</style>
